.button-100 {
    width: 100%;
}

.button-sm {
    display: grid;
    max-width: 226px;
    flex: 0 0 auto;
    width: 33.33333333%;
}

.content_button {
    cursor: pointer;

    button {
        padding: 12px var(--spacing-leftright-nano);
        gap: var(--spacing-stack-nano);
        border-radius: var(--border-radius-pill);

        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        max-width: 100%;
        max-height: 48px;
        height: 100%;

        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        &:disabled {
            background-color: var(--color-secondary-purple-100);
            border: none;
            color: var(--color-secondary-purple-300);
            opacity: var(--opacity-level-semiopaque);
        }

        &.loading {
            cursor: not-allowed;
        }
    }

    .primary {
        background-color: var(--color-primary);
        border: var(--border-width-thin) solid var(--color-primary);

        .primary:hover {
            background-color: var(--color-primary);
            border: var(--border-width-thin) solid var(--color-primary);
            transform: scale(1.01);
        }
        .primary:active {
            transform: scale(0.99);
        }
    }

    .secondary {
        background-color: var(--color-write);
        border: var(--border-width-thin) solid var(--color-secondary-purple-300);
        color: var(--color-secondary-purple-300);
    }
    .secondary:hover {
        background-color: var(--color-secondary-purple-300);
        border: var(--border-width-thin) solid var(--color-secondary-purple-300);
        color: var(--color-write);
        transform: scale(1.02);
    }
    .secondary:active {
        transform: scale(0.99);
    }

    .purple {
        color: var(--color-write);
        background-color: var(--color-support-purple);
        border: var(--border-width-thin) solid var(--color-support-purple);
    }
    .purple:hover {
        background-color: var(--color-support-purple);
        border: var(--border-width-thin) solid var(--color-support-purple);
        transform: scale(1.01);
    }
    .purple:active {
        transform: scale(0.99);
    }

    .secondary-purple {
        color: var(--color-write);
        background-color: var(--color-secondary-purple-300);
        border: var(--border-width-hairline) solid var(--color-write);
    }
    .secondary-purple:hover {
        background-color: var(--color-secondary-purple-300);
        border: var(--border-width-hairline) solid var(--color-write);
        transform: scale(1.01);

        &:disabled {
            background-color: var(--color-secondary-purple-100);
            border: none;
            color: var(--color-secondary-purple-300);
            opacity: var(--opacity-level-semiopaque);
        }
    }
    .secondary-purple:active {
        transform: scale(0.99);
    }
}
