@import "../../../../styles/mixin";

.figure-image-print-celular {
  @include devices(small) {
    display: none;
  }
  
  @include devices(xsmall) {
    display: none;
  }

  @include devices(medium) {
    background-color: red;
    display: none;
  }
 
}

.figure-image-print-celular > img {

  @include devices(xxlarge) {
    max-height: 745px;
    max-width: 540px;
    min-width: 510px;
    width: 100%;
    height: 100%;
    padding-top: var(--spacing-topbottom-xs);
  }
  
  @include devices(xlarge) {
    max-height: 745px;
    max-width: 493px;
    min-width: 460px;
    width: 100%;
    height: 100%;
    padding-top: var(--spacing-topbottom-sm);
  }
  
  @include devices(large) {
    max-height: 878px;
    max-width: 580px;
    min-width: 430px;
    width: 100%;
    height: 100%;
  }
 

}
