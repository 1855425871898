@import "../../../styles/mixin";

.divider {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 95px 0;

  span {
    border: var(--border-width-thin) solid var(--color-support-bg-gray);
    width: 80%;
  }

  @include devices(xsmall) {
    margin: 32px 0;
  }

  @include devices(small) {
    margin: 32px 0;
  }
}