.section-nossos-parceiros {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: var(--spacing-topbottom-quarck);

    :global {
        .swiper {
            width: 100%;
            height: 100%;
          }

          .swiper-slide {
            width: 200px !important;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .swiper-wrapper {
            display: flex;
            align-items: center;
          }
    }
}

.section-nossos-parceiros
    > .section-nossos-parceiros--container-heading {
    width: 100%;
    text-align: center;
}

.section-nossos-parceiros
    > .section-nossos-parceiros--container {
    background-color: var(--color-support-bg-gray);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-inline-xs);

    justify-content: space-evenly;
    align-items: center;

    height: 100%;

    flex-wrap: wrap;
    display: flex;
    gap: var(--spacing-leftright-md);
}

.section-nossos-parceiros
    > .section-nossos-parceiros--container {
    .section-nossos-parceiros--container__img-tempo {
        width: 130px;
        height: 38px;
    }
}

.section-nossos-parceiros
    > .section-nossos-parceiros--container {
    .section-nossos-parceiros--container__img-gympass {
        width: 140px;
        height: 30px;
    }
}

.section-nossos-parceiros
    > .section-nossos-parceiros--container {

    .section-nossos-parceiros--container__img-drogasil {
        width: 200px;
        height: 45px;
    }
}

.section-nossos-parceiros
    > .section-nossos-parceiros--container {

    .section-nossos-parceiros--container__img-droga-raia {
        width: 115px;
        height: 40px;
    }
}

.section-nossos-parceiros
    > .section-nossos-parceiros--container {
    .section-nossos-parceiros--container__img-labi-exames {
        width: 90px;
        height: 50px;
    }
}

.section-nossos-parceiros
    > .section-nossos-parceiros--container {
    .section-nossos-parceiros--container__img-queima-diaria {
        width: 130px;
        height: 20px;
    }
}