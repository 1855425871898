@import "../../../../styles/mixin";

.modal-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255 255 255 / 90%);

  .modal-body {
    background-color: var(--color-secondary-purple-300);
    border-radius: 24px;
    position: relative;
    max-width: 80vw;
    max-height: 90vh;
    overflow-y: auto;

    @include devices(xsmall) {
      max-width: initial;
      max-height: initial;
      height: 100vh;
      width: 100vw;
      border-radius: 0px;
    }

    @include devices(small) {
      max-width: initial;
      max-height: initial;
      height: 100vh;
      width: 100vw;
      border-radius: 0px;
    }

    @include devices(medium) {
      max-width: initial;
      max-height: initial;
      height: 100vh;
      width: 100vw;
      border-radius: 0px;
    }

    .close-button {
      width: 40px;
      height: 40px;
      background-color: var(--color-secondary-purple-100);
      border-radius: 50%;
      display: flex;
      place-items: center;
      place-content: center;
      position: absolute;
      right: 44px;
      top: 42px;
      cursor: pointer;
      outline: 0;
      border: none;
      margin: 0;
      z-index: 1;

      i {
        position: relative;

        svg {
          position: relative;
          top: -1px;
        }
      }

      svg {
        width: 14px;
        height: 14px;
      }

      @include devices(xsmall) {
        width: 29px;
        height: 29px;
        right: 13px;
        top: 17px;

        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}