@import "../../../../styles/mixin";

.figure__image-capa img {
  z-index: 0;

  width: 329px;
  height: 87px;
  object-fit: contain;

  @include devices(xxlarge) {
  }

  @include devices(xlarge) {

  }

  @include devices(large) {

  }

  @include devices(medium) {

  }

  @include devices(small) {
    width: 98px;
    height: 27px;
  }

  @include devices(xsmall) {
    width: 98px;
    height: 27px;
  }
}
