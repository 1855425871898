@import "../../../styles/mixin";

.section-baixe-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    gap: var(--spacing-inset-xxs);
    
    .container-app-options {
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        gap: var(--spacing-inset-lg);
        background-color: var(--color-support-bg-gray);
        
        @include devices(xsmall) {
            padding: 12px var(--spacing-leftright-sm);
        }

        @include devices(small) {
            padding: 12px var(--spacing-leftright-sm);
        }

        .section-baixe_app-options {
            display: grid;
            grid-template-columns: 100px 1fr;

            @include devices(xsmall) {
                grid-template-columns: 1fr !important;
                grid-template-rows: max-content 1fr;
                gap: 8px;


                h6, p {
                    font-size: 11px !important;
                    line-height: 15px;
                }
            }

            @include devices(small) {
                grid-template-columns: 1fr !important;
                grid-template-rows: max-content 1fr;
                gap: 8px;


                h6, p {
                    font-size: 11px !important;
                    line-height: 15px;
                }
            }
        }
    }

    .section-oque-oferecemos_container-title_heading {
        @include devices(xsmall) {
            font-size: 20px;
        }

        @include devices(small) {
            font-size: 20px;
        }
    }
}