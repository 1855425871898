@import "../../../../../styles/mixin";

.modal-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  position: relative;
  padding: 38px 119px 28px 38px;

  @include devices(xsmall) {
    padding: 32px 24px;
    align-items: start;

    > p {
      width: 80%;
      align-items: start;
    }
  }

  @include devices(small) {
    padding: 32px 24px;
    align-items: start;

    > p {
      width: 80%;
      align-items: start;
    }
  }

  @include devices(medium) {
    padding: 32px 24px;
    align-items: start;

    > p {
      width: 80%;
      align-items: start;
    }
  }

  h5 {
    color: var(--color-write);

    @include devices(xsmall) {
      display: none;
    }

    @include devices(small) {
      display: none;
    }

    @include devices(medium) {
      display: none;
    }
  }

  .form-data {
    display: grid;
    max-width: 1161px;
    grid-template-columns: 1fr 1fr;
    gap: 129px;
    width: 100%;
    align-items: center;

    :global {
      .spinner-border {
        color: var(--color-secondary-purple-300);
      }
    }

    @include devices(xsmall) {
      grid-template-columns: 1fr;

      > figure {
          display: none;
        }
    }

    @include devices(small) {
      grid-template-columns: 1fr;

      > figure {
          display: none;
        }
    }

    @include devices(medium) {
      grid-template-columns: 1fr;

      > figure {
          display: none;
        }
    }

    label {
      color: var(--color-write);

      @include devices(xsmall) {
        font-size: 14px;
      }

      @include devices(small) {
        font-size: 14px;
      }

      @include devices(medium) {
        font-size: 14px;
      }
    }

    button {
      margin-top: 25px;
      color: var(--color-secondary-purple-300);
    }
  }
}