@import "../../../styles/mixin";

.rating_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--spacing-stack-xxs);
    gap: var(--spacing-inline-xxxs);

    @include devices(xsmall) {
        padding: var(--spacing-stack-xxs) 0;
    }

    @include devices(small) {
        padding: var(--spacing-stack-xxs) 0;
    }

    &__header {
        display: flex;
        align-items: center;

        &__logo {
            img {
                @include devices(xsmall) {
                    width: 149px;
                    height: 62px;
                }

                @include devices(small) {
                    width: 149px;
                    height: 62px;
                }

                @include devices(medium) {
                    width: 149px;
                    height: 62px;
                }
            }
        }
    }

    &__content_card {
        max-width: 630px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-inline-xxxs);
        text-align: center;

        &__content_rating {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;

            &__stars {
                display: flex;
                align-items: center;

                .star {
                    font-size: 24px;
                    color: #ccc;
                    cursor: pointer;
                }

                .filled {
                    color: var(--color-primary-yellow-200);
                }
            }
        }

        &__question {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: stretch;
            gap: var(--spacing-stack-nano);
            width: 100%;

            textarea {
                width: 100%;
                height: 100px;
                padding: 10px;
                border: 1px solid var(--color-neutral-100);
                border-radius: 5px;
                resize: vertical;
                background-color: var(--color-neutral-000);
            }

            &__feedback_stars {
                display: flex;
                align-items: center;
                gap: var(--spacing-stack-nano);

                .star {
                    font-size: 32px;
                    color: var(--color-neutral-100);
                    cursor: pointer;
                    margin-right: 5px;
                }

                .filled {
                    color: var(--color-primary-yellow-200);
                }
            }

            &__scale {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: var(--spacing-stack-xxs);
                padding: var(--spacing-inline-nano) 0;

                @include devices(xsmall) {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    justify-content: center;
                    gap: var(--spacing-inline-xxxs);
                }

                @include devices(small) {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    justify-content: center;
                    gap: var(--spacing-inline-xxxs);
                }

                &__content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    &__icon_mobile {
                        display: none;

                        @include devices(xsmall) {
                            display: block;
                        }

                        @include devices(small) {
                            display: block;
                        }
                    }

                    &__icon_desktop {
                        display: block;

                        @include devices(xsmall) {
                            display: none;
                        }

                        @include devices(small) {
                            display: none;
                        }
                    }

                    .dot {
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        background-color: white;
                        border: 1px solid var(--color-neutral-100);
                        cursor: pointer;

                        @include devices(xsmall) {
                            width: 30px;
                            height: 30px;
                            background-color: var(--color-support-bg-purple);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                        }

                        @include devices(small) {
                            width: 30px;
                            height: 30px;
                            background-color: var(--color-support-bg-purple);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                        }
                    }

                    .selected {
                        background-color: var(--color-primary-yellow-200);
                        border-color: var(--color-primary-yellow-200);

                        @include devices(xsmall) {
                            background-color: var(--color-secondary-purple-300);
                            border-color: var(--color-secondary-purple-300);
                            color: white !important;
                        }
                    }
                }
            }
        }

        &__button {
            width: 100%;
            padding-top: var(--spacing-inline-nano);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: stretch;

            small {
                font-size: 13px;
            }

            width: 80%;

            div {
                display: contents;
            }

            @include devices(xsmall) {
                width: 100%;

                div {
                    display: contents;
                }
            }

            @include devices(small) {
                width: 100%;

                div {
                    display: contents;
                }
            }
        }
    }
}
