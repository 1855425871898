@import "../../../styles/mixin";

.widget-logo {
    img {
        width: 149px;
        height: 62px;

        cursor: pointer;

        @include devices(xsmall) {
            width: 80px;
            height: 35px;
        }

        @include devices(small) {
            width: 80px;
            height: 35px;
        }

        @include devices(medium) {
            width: 48px;
            height: 20px;
        }
    }
}
