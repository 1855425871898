:root {
    --color-primary: #FFCC00;
    --color-neutral-button: #DCE0E6;
    --color-font-black: #201A00;

    --color-primary-yellow-100: #ffe680;
    --color-primary-yellow-200: #ffcc00;
    --color-primary-yellow-300: #DBAF00;
    --color-primary-yellow-400: #A58400;

    --color-secondary-purple-100: #F5F5FF;
    --color-secondary-purple-200: #E8E9FF;
    --color-secondary-purple-300: #4C2450;

    --color-pink-100: #F9DCF0;
    --color-pink-200: #F1A7DB;
    --color-pink-300: #EA79C8;
    --color-pink-400: #E34FB7;
    --color-pink-500: #841563;

    --color-blue-100: #8AB0BC;
    --color-blue-200: #56CCF2;
    --color-blue-300: #00ADFF;
    --color-blue-400: #8A96BC;
    --color-blue-500: #263257;

    --color-green-100: #CDF4DD;
    --color-green-200: #8ABCA4;
    --color-green-300: #00980C;
    --color-green-400: #263257;
    --color-green-500: #1D2F1F;

    --color-write: #FFFFFF;
    --color-neutral: #A9ABAB;
    --color-neutral-000: #F4F4F5;
    --color-neutral-100: #CACACE;
    --color-neutral-200: #9F9FA7;
    --color-neutral-300: #63636B;
    --color-neutral-400: #222222;
    --color-neutral-500: #141415;

    --color-support-bg-green: #BEF9F1;
    --color-support-bg-purple: #F0F0FF;
    --color-support-bg-gray: #F5F5F5;
    --color-support-bg-red: #FDE5DD;
    --color-support-bg-orange: #FDE5DD;

    --color-support-purple: #8A8FFF;
    --color-support-purple-2: #8C70CC;

    --color-support-green: #10A794;
    --color-support-purple: #8A8FFF;
    --color-support-gray: #C9C9C9;
    --color-support-red: #F24E15;
    --color-support-orange: #D78E00;
}

:root {
    --border-radius-pill: 100px;
    --border-radius-none: none;
    --border-radius-sm: 4px;
    --border-radius-md: 8px;
    --border-radius-lg: 16px;
    --border-radius-circle: 50%;

    --border-width-none: 0px;
    --border-width-hairline: 1px;
    --border-width-thin: 2px;
    --border-width-trick: 4px;
    --border-width-heavy: 8px;

    --opacity-level-semiopaque: 0.80;
    --opacity-level-intense: 0.64;
    --opacity-level-medium: 0.32;
    --opacity-level-light: 0.16;
    --opacity-level-semitransparent: 0.08;

    --shadow-level-1: 0px 2px 3px;
    --shadow-level-2: 0px 3px 6px;
    --shadow-level-3: 0px 8px 24px;
    --shadow-level-4: 0px 16px 32px;

    --avatar-circle-sm: 40px;
    --avatar-circle-md: 64px;
    --avatar-circle-lg: 96px;

    --spacing-stack-quarck: 4px;
    --spacing-stack-nano: 8px;
    --spacing-stack-xxxs: 16px;
    --spacing-stack-xxs: 24px;
    --spacing-stack-xs: 32px;
    --spacing-stack-sm: 40px;
    --spacing-stack-md: 48px;
    --spacing-stack-lg: 56px;
    --spacing-stack-xl: 64px;
    --spacing-stack-xxl: 80px;
    --spacing-stack-xxxl: 120px;
    --spacing-stack-huge: 160px;
    --spacing-stack-giant: 200px;

    --spacing-inline-quarck: 4px;
    --spacing-inline-nano: 8px;
    --spacing-inline-xxxs: 16px;
    --spacing-inline-xxs: 24px;
    --spacing-inline-xs: 32px;
    --spacing-inline-sm: 40px;
    --spacing-inline-md: 48px;
    --spacing-inline-lg: 62px;
    --spacing-inline-xl: 80px;

    --spacing-inset-quarck: 4px;
    --spacing-inset-nano: 8px;
    --spacing-inset-xxs: 16px;
    --spacing-inset-sm: 24px;
    --spacing-inset-md: 32px;
    --spacing-inset-lg: 40px;
    
    --spacing-squish-quarck: 4px 8px;
    --spacing-squish-nano: 8px 16px;
    --spacing-squish-xs: 16px 32px;
    --spacing-squish-sm: 24px 32px;
    --spacing-squish-md: 32px 40px;
    --spacing-squish-lg: 40px 48px;

    --spacing-topbottom-quarck: 4px;
    --spacing-topbottom-nano: 8px;
    --spacing-topbottom-xs: 16px;
    --spacing-topbottom-sm: 24px;
    --spacing-topbottom-md: 32px;
    --spacing-topbottom-lg: 40px;

    --spacing-leftright-quarck: 4px;
    --spacing-leftright-nano: 8px;
    --spacing-leftright-xs: 16px;
    --spacing-leftright-sm: 24px;
    --spacing-leftright-md: 32px;
    --spacing-leftright-lg: 40px;
}

:root {
    --font-size-h1: 96px;
    --font-size-h2: 60px;
    --font-size-h3: 48px;
    --font-size-h4: 32px;
    --font-size-h5: 24px;
    --font-size-h6: 20px;

    --font-size-sub-title-1: 32px;
    --font-size-sub-title-2: 22px;

    --font-size-body-text-1: 20px;
    --font-size-body-text-2: 18px;
    --font-size-body-text-3: 14px;
    --font-size-body-text-4: 12px;

    --font-size-h1-min: 60px;
    --font-size-h2-min: 48px;
    --font-size-h3-min: 32px;
    --font-size-h4-min: 24px;
    --font-size-h5-min: 20px;

    --font-size-sub-title-1-min: 24px;
    --font-size-sub-title-2-min: 20px;

    --font-size-body-text-1-min: 18px;
    --font-size-body-text-2-min: 16px;
}
