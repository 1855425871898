@import "../../../styles/mixin";

.section-main {
    min-height: 85vh;
    padding: 0 0 66px 42px;
    display: grid;
    grid-template-columns: 100%;
    position: relative;
    padding-top: 65px;

    @include devices(medium) {
        min-height: 88vh;
    }

    @include devices(small) {
        min-height: 30vh;
        max-height: 50vh;
        height: -webkit-fill-available;
        padding-left: 17px;
        padding-top: 0;
        padding-bottom: 0;
    }

    @include devices(xsmall) {
        min-height: 30vh;
        max-height: 50vh;
        height: -webkit-fill-available;
        gap: 8px;
        padding-left: 17px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .section-section-main__container {
        display: flex;
        flex-direction: column;
        gap: 48px;
        position: relative;
        width: 40%;
        margin-top: var(--spacing-inline-lg);
        
        @include devices(xsmall) {
            margin-top: var(--spacing-inline-xs);
            gap: 28px;
        }

        .section-main__container_content--heanding {
            width: max-content;

            span {
                color: var(--color-primary);
            }

            @include devices(xsmall) {
                font-size: 30px;
                width: max-content;
                margin-top: 0px;
            }

            @include devices(small) {
                font-size: 30px;
                width: max-content;
                margin-top: 0;
            }
        }

        .store-links {
            figure {
                margin-top: 25px;
            }

            @include devices(xsmall) {
                span {
                    font-size: 12px !important;
                    line-height: 16px;
                }
            }

            @include devices(small) {
                span {
                    font-size: 12px !important;
                    line-height: 16px;
                }
            }
        }
    }

    .section-main__container_content--figure {
        position: absolute;
        position: absolute;
        right: 0;
        left: 50%;
        top: 50%;
        z-index: -1;
        transform: translateY(-50%);

        .img-mains-logos {
            position: absolute;
            width: 116px;
            object-fit: contain;
            top: 50%;
            left: -50px;
            transform: translateY(-50%);

            @include devices(xsmall) {
                max-width: 580px;
                width: 60px;
                left: -20px;
                margin-top: 50px;
            }
            
            @include devices(small) {
                max-width: 580px;
                width: 60px;
                left: -20px;
                margin-top: 50px;
            }
        }

        .img-alongando {
            position: absolute;
            bottom: -10%;
            right: 10%;
        }

        @include devices(xsmall) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            figure {
                margin-top: 100px;
            }
        }

        @include devices(small) {
            display: flex;
            flex-direction: column;
            justify-content: center;
        
            figure {
                margin-top: 100px;
            }
        }
    }

    .login-text {
        width: max-content;
        gap: var(--spacing-inline-nano);
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            text-decoration: none;
            font-weight: 700;
        }

        &,
        a {
            @include devices(xsmall) {
                font-size: 14px !important;
            }

            @include devices(small) {
                font-size: 14px !important;
            }
        }
    }
}
