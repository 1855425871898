@import "../../../styles/mixin";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    height: 100%;
    gap: var(--spacing-inline-xxs);

    .header {
        display: flex;
        align-items: center;

        &__logo {

            img {
                @include devices(xsmall) {
                    width: 149px;
                    height: 62px;
                }
    
                @include devices(small) {
                    width: 149px;
                    height: 62px;
                }

                @include devices(medium) {
                    width: 149px;
                    height: 62px;
                }
            }
        }
    }

    .content {
        max-width: 600px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: stretch;
        text-align: center;
        gap: var(--spacing-inline-xxs);

        figure {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0;
            img {
                max-height: 480px;
                height: 100%;
                max-width: 620px;
                width: 100%;
            }
        }

        &__button {
            width: 80%;
                
            div {
                display: contents;
            }
            
            @include devices(xsmall) {
                width: 100%;

                div {
                    display: contents;
                }
            }

            @include devices(small) {
                width: 100%;

                div {
                    display: contents;
                }
            }

        }
    }
}
