@import "../../../styles/mixin";

@mixin column {
    display: flex;
    flex-direction: column;
}

.section-saude {
    background-color: var(--color-neutral-000);
    color: var(--color-write) ;

    padding: var(--spacing-stack-xl) 0;

    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.section-saude
    > .section-saude__container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.section-saude
    > .section-saude__container
    > .section-saude__container--content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-around;
}

.section-saude
    > .section-saude__container
    > .section-saude__container--content
    > .section-saude__container-title {

    @include devices(medium){
        h1 {
            font-size: var(--font-size-h2-min);
            line-height: 30px;
        }

        h3 {
            font-size: var(--font-size-h4-min);
            line-height: 10px;
        }
    }

    @include devices(small){
        h1 {
            font-size: var(--font-size-h3-min);
            line-height: 30px;
        }

        h3 {
            font-size: var(--font-size-h4-min);
            line-height: 20px;
        }
    }
    @include devices(xsmall){
        h1 {
            font-size: var(--font-size-h3-min);
            line-height: 30px;
        }

        h3 {
            font-size: var(--font-size-h5-min);
            line-height: 10px;
        }
    }
}

.section-saude
    > .section-saude__container
    > .section-saude__container--content
    > .section-saude__container-body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @include devices(small){
        span {
            font-size: 14px !important;
            line-height: 20px;
        }
    }
    @include devices(xsmall){
        span {
            font-size: 14px !important;
            line-height: 20px;
        }
    }
}

.section-saude
    > .section-saude__container
    > .section-saude__container--content
    > .section-saude__container-body
    > figure {

    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;

    img {
        max-height: 600px;
        min-height: 530px;
        height: 100%;
        max-width: 690px;
        min-width: 503px;
        width: 100%;

        @include devices(small){
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            width: 150px;
            height: 215px;
            min-width: 100px;
            min-height: 175px;
            right: -10px;
            margin: 100px 0 0 0;
        }
        @include devices(xsmall) {

            max-width: 100%;
            max-height: 100%;

            width: 240px;
            height: 250px;

            min-width: 215px;
            min-height: 225px;

            margin-right: -15px;
        }
    }
}

.section-saude
    > .section-saude__container
    > .section-saude__container--content
    > .section-saude__container--button
    > a {
    padding: var(--spacing-squish-md);
    gap: var(--spacing-stack-nano);
    border-radius: var(--border-radius-pill);

    background-color: var(--color-secondary-purple-300);
    color: var(--color-write);

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 100%;
    max-height: 48px;
    height: 100%;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
}

.section-saude
    > .section-saude__container
    > .section-saude__container--content
    > .section-saude__container--button {

    a:nth-child(2) {
        display: none;
    }

    @include devices(small) {
        a:nth-child(1) {
            display: none;
        }
        a:nth-child(2) {
            display: flex;
            padding: 12px var(--spacing-leftright-nano);
        }
    }
    @include devices(xsmall) {
        a:nth-child(1) {
            display: none;
        }
        a:nth-child(2) {
            display: flex;
            padding: 12px var(--spacing-leftright-nano);
        }

    }
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-squish-md);

    &__article_submit {
        @include column();
        justify-items: center;
        gap: var(--spacing-topbottom-sm) 0;

        width: 100%;

        figure {
            margin: 0;

            img {
                max-width: 250px;
            }
        }

        &__title {
            padding: var(--spacing-topbottom-quarck) 0;
        }

        &__footer {
            width: 75%;
            align-self: center;

            margin-bottom: var(--spacing-inline-xxs);

            &__button {
                color: var(--color-secondary-purple-300);
            }
        }
    }
}

.modal_small {
    width: 100% !important;
    max-width: 380px;
    min-width: 40% !important;
    min-height: 75svh !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-squish-md);
    overflow: hidden;
}