.loader {
    &:not(.bar) {
        display: inline-block;
        border: 4px solid rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        border-top: 4px solid var(--color-secondary-purple-300);
        width: 20px;
        height: 20px;
        animation: spin 1s linear infinite;
    }

    &.bar {
        height: 100%;
        width: 50%; 
        background-color:var(--color-secondary-purple-300);
        border-radius: 4px;
        animation: progress 1s linear infinite; 
    }
}

.container {
    &:not(.bar) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    
    &.bar {
        width: 100%;
        height: 12px;
        background-color: #f3f3f3;
        border-radius: 5px;
        overflow: hidden;
        margin: 12px 0;
    }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@keyframes progress {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
}
