@import '../../../styles/mixin';

@mixin column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section_faca_parte {
    @include column();

    &__container {
        max-width: 1140px;
        width: 100%;
        padding: 0 var(--spacing-leftright-xs);

        @include column();
        
        &__title {
            @include devices(xsmall){
                font-size: var(--font-size-h5);
                line-height: 30px;
            }

            @include devices(small) {
                font-size: var(--font-size-h5);
                line-height: 30px;
            }
        }

        &__article {
            padding: var(--spacing-topbottom-lg) 0;

            &__content {
                @include column();
                
                & > img {
                    padding: var(--spacing-topbottom-sm) 0;
                }

                &__card {             
                    max-width: 780px;
                    width: 100%;
                    height: 300px;
    
                    padding: var(--spacing-squish-lg);
                    background-color: var(--color-secondary-purple-200);
                    border-radius: var(--border-radius-lg);
    
                    justify-content: center;

                    @include column();
                    
                    @include devices(xsmall){
                        height: 260px;
                    }
        
                    @include devices(small) {
                        height: 260px;
                    }                   

                    &__container {
                        width: 100%;
                        max-width: 610px;
                        text-align: center;                      

                        & > h4 {
                            margin: 0;
                            
                            @include devices(xsmall){
                                font-size: var(--font-size-body-text-2-min);
                                line-height: 25px;
                            }
                
                            @include devices(small) {
                                font-size: var(--font-size-h5);
                                line-height: 30px;
                            }
                        }
                    }
                }
                
            }
        }

        &__article_form_modal {
            @include column();
            justify-content: center;

            text-align: center;
            max-width: 880px;

            gap: var(--spacing-topbottom-sm);

        }
    }
}
