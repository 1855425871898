
.section-duvidas-frequentes {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 45px;
}

.section-duvidas-frequentes
    > .section-duvidas-frequentes__container {

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.section-duvidas-frequentes
    > .section-duvidas-frequentes__container
    > .section-duvidas-frequentes__container__content-collapse {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-inline-xxxs);
}


.section-duvidas-frequentes
    > .section-duvidas-frequentes__container
    > .section-duvidas-frequentes__container__content-collapse
    > h2 {
    font-size: var(--font-size-h3-min);
    line-height: 35px;
}

.section-duvidas-frequentes
    > .section-duvidas-frequentes__container
    > .section-duvidas-frequentes__container__figure {

    img {
        width: 250px;
        height: 280px;
    }
}
