
@import "../../../../../styles/mixin";

.figure__image-capa > img {
  z-index: 0;

  width: 100%;
  object-fit: contain;
  max-width: 397px;

  @include devices(xxlarge) {
  }

  @include devices(xlarge) {
  }

  @include devices(large) {
  }

  @include devices(medium) {
  }

  @include devices(small) {
  }

  @include devices(xsmall) {
    max-width: 250px;
  }
}
