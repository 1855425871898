@import "../../../styles/mixin";

.section-gerencionamento {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: space-between;
  
  height: 100%;
  width: 100%;
}

.section-gerencionamento > .section-gerencionamento--container {
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  width: 100%;
  height: 100%;

}

.section-gerencionamento
  > .section-gerencionamento--container
  > .section-gerencionamento--container__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;

    @include devices(xxlarge) {
      width: 740px;
    }
    
    @include devices(xlarge) {
      width: 740px;
    }
    
    @include devices(large) {
      width: 600px;
    } 

    span {
        color: var(--color-support-purple);
    } 
}

.section-gerencionamento
  > .section-gerencionamento--container
  > .section-gerencionamento--container__content
  > .section-gerencionamento--container__content_card-icons {
    display: flex;
    flex-wrap: wrap-reverse;
    
    @include devices(xsmall) {
      justify-content: center;
      gap: var(--spacing-inline-md);
    }
    @include devices(small) {
      justify-content: center;
    }

    figure{
      width: 16%;
    }
}

.section-gerencionamento
  > .section-gerencionamento--container
  > .section-gerencionamento--container__content
  > .section-gerencionamento--container__content-heading {

    @include devices(medium){
      font-size: var(--font-size-h2-min);
      line-height: 50px;
    }

    @include devices(small){
      font-size: var(--font-size-h3-min);
      line-height: 35px;
    }
    
    @include devices(xsmall) {
      font-size: var(--font-size-h3-min);
      line-height: 35px;
    }
}

.section-gerencionamento
  > .section-gerencionamento--container
  > .section-gerencionamento--container__content
  > .section-gerencionamento--container__content_sub-title {

    .section-gerencionamento--container__content_sub-title_heading {
      @include devices(small){
        font-size: var(--font-size-h5-min);
        line-height: 25px;
      }
  
      @include devices(xsmall) {
        font-size: var(--font-size-h5-min);
        line-height: 25px;
      }
    }

    .section-gerencionamento--container__content_sub-title_paragraph {
      @include devices(small){
        font-size: 16px !important;
        line-height: 23px;
      }
  
      @include devices(xsmall) {
        font-size: 14px !important;
        line-height: 23px;
      }
    }
}
