.input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-inset-micro);
}

.input {
    background-color: #F8F8F6;
    border-color: #F8F8F6;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    padding: var(--spacing-squish-nano);
    gap: var(--spacing-inset-nano);

    border-radius: var(--border-radius-md);
}

.is-valid {
    box-shadow: 0 0 0 0.15rem var(--color-green-300);
}

.is-invalid {
    box-shadow: 0 0 0 0.20rem var(--color-support-red);
}