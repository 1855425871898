@import "../../../styles/mixin";

.section-beneficios {
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: center;
    padding: 0 32px;

    .section-beneficio_container__content-card {
        border-radius: var(--border-radius-lg);
        padding: 40px 73px;
        background-color: var(--color-secondary-purple-300);
        color: var(--color-write);
        width: 100%;

        &.primary {
            background-color: var(--color-primary);

            p {
                color: var(--color-secondary-purple-300) !important;
            }
        }

        p {
            font-size: 30px !important;
            text-align: center;
            line-height: 45px;

            span {
                font-weight: bold;
                font-size: 36px;
            }
        }

        @include devices(xsmall) {
            p {
                font-size: 15px !important;
                line-height: 20px;

                span {
                    font-size: 30px;
                    line-height: 30px;
                }
            }
        }

        @include devices(small) {
            p {
                font-size: 15px !important;
                line-height: 20px;

                span {
                    font-size: 30px;
                    line-height: 30px;
                }
            }
        }
    }

    .nossos-planos {
        font-size: 30px !important;
        text-align: center;
        line-height: 45px;
        font-weight: 700;
        width: 80%;

        span {
            color: var(--color-primary);
        }

        @include devices(xsmall) {
            font-size: 20px !important;
            line-height: 20px;
            width: 90%;
        }

        @include devices(small) {
            font-size: 20px !important;
            line-height: 20px;
            width: 90%;
        }
    }
}
