@import '../../../styles/mixin';

@mixin column {
    display: flex;
    flex-direction: column;
}

.modal_vem_fazer_parte {
    display: grid;
    grid-template-columns: 2fr 2fr;
    
    justify-items: center;

    &__article_title {
        @include column();

        gap: var(--spacing-topbottom-xs);
        max-width: calc(100svh - 50%);

        figure {
            margin: 0;

            @include devices(xsmall){
                display: none;
            }
        
            @include devices(small) {
                display: none;
            }
        }
    }

    &__article_submit {
        @include column();

        justify-items: center;
        gap: var(--spacing-topbottom-md) 0;

        width: 100%;

        figure {
            margin: 0;
        }

        &__title {
            padding: var(--spacing-topbottom-quarck) 0;
        }

        &__footer {
            width: 75%;
            align-self: center;

            margin-bottom: var(--spacing-inline-xxs);

            &__button {
                color: var(--color-secondary-purple-300);
            }
        }
    }

    @include devices(xsmall){
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    @include devices(small){
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

.modal_vem_fazer_parte_submit {
    display: flex;
    flex-direction: column;

    @include devices(xsmall){
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    @include devices(small) {
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}