@import "../../../styles/mixin";

.section-gerencionamento {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: space-between;

  height: 100%;
  width: 100%;

  .seja-protagonista {
    height: 125px;
    background-color: var(--color-secondary-purple-200);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 28px;

    @include devices(xsmall) {
      padding: 12px 50px !important;
      margin-bottom: 32px;

      h4 {
        font-size: 20px !important;
      }

      p {
        font-size: 10px;
      }
    }

    @include devices(small) {
      padding: 12px 50px !important;
      margin-bottom: 32px;

      h4 {
        font-size: 20px !important;
      }

      p {
        font-size: 10px;
      }
    }

    h4 {
      text-align: center;
      margin-bottom: 2px !important;
    }

    span {
      color: var(--color-support-purple);
    }

    p {
      font-weight: 500;
    }
  }

  .section-historico--container {
    padding: 20px 108px;
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    justify-content: space-between;
    gap: 80px;

    @include devices(xsmall) {
      grid-template-columns: 1fr !important;
      grid-template-rows: max-content 1fr;
      justify-items: center;
      gap: 26px;
      padding: 0 24px;

      .img-moca, .img-consulta-presencial {
        grid-row: 1;
      }

      .section-gerencionamento--container__content_sub-title_heading {
        font-size: 15px;
        line-height: 15px;
      }

      .section-gerencionamento--container__content_sub-title_paragraph {
        font-size: 11px !important;
        line-height: 15px;
      }
    }

    @include devices(small) {
      grid-template-columns: 1fr !important;
      grid-template-rows: max-content 1fr;
      justify-items: center;
      gap: 26px;
      padding: 0 24px;

      .img-moca, .img-consulta-presencial {
        grid-row: 1;
      }

      h4 {
        font-size: 15px;
      }

      p {
        font-size: 11px !important;
      }
    }

    &.img-left {
      grid-template-columns: max-content 1fr;
    }

    .img-moca {
      img {
        width: 134px;
        height: 233px;
      }
    }

    .img-colaboradores {
      img {
        width: 317px;
        height: 188px;
      }
    }

    .img-consulta-presencial {
      img {
        width: 324px;
        height: 169px;
      }
    }
  }
}
