@import "../../../../styles/mixin";

.figure__image-capa {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        z-index: 0;
    
        width: 100%;
        height: 100%;
    
        max-height: 500px;
        max-width: 700px;
    
        @include devices(xsmall) {
            max-width: 280px;
            width: 100%;
        }
    
        @include devices(small) {
            max-width: 280px;
            width: 100%;
        }
    }
}
