@import '../../../styles/mixin';

.section-cadastro__container {
    border-bottom: var(--border-width-thin) solid var(--color-support-bg-gray);   
    padding: var(--spacing-topbottom-md) 0;
    
    gap: var(--spacing-inline-quarck);
    
    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;

    a {
        color: var(--color-font-black);
    }
}
