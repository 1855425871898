@import "../../styles/mixin";

.icons > img {
  width: 80px;
  height: 80px;

  @include devices(medium) {
    width: 65px;
    height: 65px;
  }

  @include devices(small) {
    width: 65px;
    height: 65px;
  }

  @include devices(xsmall) {
    width: 65px;
    height: 65px;
  }

}

.icons-telemedicina {
  img {
    width: 28px;
    height: 40px;

    @include devices(xsmall) {
      width: 14px;
      height: 20px;
    }

    @include devices(small) {
      width: 14px;
      height: 20px;
    }
  }
}

.icons-usuario {
  img {
    width: 44px;
    height: 34px;

    @include devices(xsmall) {
      width: 22px;
      height: 17px;
    }

    @include devices(small) {
      width: 22px;
      height: 17px;
    }
  }
}

.icons-dado-consulta {
  img {
    width: 32px;
    height: 40px;

    @include devices(xsmall) {
      width: 16px;
      height: 20px;
    }

    @include devices(small) {
      width: 16px;
      height: 20px;
    }
  }
}

.icons-historico {
  img {
    width: 32px;
    height: 41px;

    @include devices(xsmall) {
      width: 16px;
      height: 20px;
    }

    @include devices(small) {
      width: 16px;
      height: 20px;
    }
  }
}

.icons-triagem {
  img {
    width: 40px;
    height: 40px;

    @include devices(xsmall) {
      width: 20px;
      height: 20px;
    }

    @include devices(small) {
      width: 20px;
      height: 20px;
    }
  }
}