@import "../../../styles/mixin";

.section-recomendacoes {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  padding: var(--spacing-stack-xl);
  border-radius: 15px;
  margin: var(--spacing-stack-xl) 0px;
  width: 100%;

  @include devices(xsmall) {
    padding: var(--spacing-stack-xs);
  }

  @include devices(small) {
    padding: var(--spacing-stack-xs);
  }

  @include devices(medium) {
    padding: var(--spacing-stack-xs);
  }

  @include devices(large) {
    padding: var(--spacing-stack-xs);
  }

  
}
