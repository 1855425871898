@import "../../../../styles/mixin";

.figure__image-capa > img {
  z-index: 0;
  object-fit: contain;
  width: 100%;

  @include devices(xxlarge) {
    
  }

  @include devices(xlarge) {
  }

  @include devices(large) {
  }

  @include devices(medium) {
  }

  @include devices(small) {
  }

  @include devices(xsmall) {
  }
}
