@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";

@import "./_mixin.scss";
@import "./variables.scss";

@font-face {
  font-family: "Inter", sans-serif;
  src: url("https://rsms.me/inter/inter.css");
  font-weight: normal;
  font-style: normal;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: inherit;
  font-size: inherit;
  color: inherit;
}

body::-webkit-scrollbar {
  width: 0.7em;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--color-secondary-purple-200);
  outline: 1px solid var(--color-secondary-purple-200);
}

img {
  max-width: 100%;
  height: auto;
}

section {
  padding: var(--spacing-topbottom-lg) 0;
}

#shadow-host-companion {
  padding: 0;
}
