$margin-top: 100px;
@import "../../../styles/mixin";


.section-funcionalidades {
  margin-top: $margin-top * 1.5;
  background-color: #f5f5f5;
  color: var(--color-secondary-purple-300);
  border-radius: var(--spacing-stack-xxxs);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px var(--spacing-stack-xl) var(--spacing-stack-lg) var(--spacing-stack-xl);
  font-size: 16px;
  height: auto;

  @include devices(xsmall) {
    font-size: 12px;
  }

  @include devices(small) {
    font-size: 14px;
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }

  & > img {
    margin-top: -$margin-top;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--spacing-stack-sm);

    &__text {
      flex: 1;
      min-width: 260px;
      margin-top: 24px;
      margin-right: var(--spacing-stack-md);

      &__resultado {
        margin-top: var(--spacing-stack-xxxs);
        font-size: 2.18em;
        line-height: 35px;
        font-weight: 700;

        & > span {
          color: var(--color-primary);
        }
      }

      &__pratica {
        margin-top: var(--spacing-stack-lg);
        font-weight: 700;
        font-size: 1.87em;
        line-height: 30px;
      }

      @include devices(xsmall) {
        min-width: 100px;
        font-size: 1em;
        margin-left: 18px;
      }

    }

    &__list {
      flex: 4;
      min-width: 500px;

      @include devices(xsmall) {
        min-width: 300px;
      }
    
      @include devices(small) {
        min-width: 320px;
      }

      img {
        width: 50px;
        height: 50px;

        @include devices(xsmall) {
          width: 20px;
          margin-left: 24px;
        }
      
        @include devices(small) {
          width: 24px;
        }

        margin-right: var(--spacing-stack-xxxs);
      }

      li {
        width: 100%;
        display: flex;
        align-items: center;
        list-style-type: none;
        margin-top: 24px;

        & > p {
          font-weight: 500;
          font-size: 1.87em !important;
          line-height: 35px;
          color: var(--color-secondary-purple-300);
          margin-bottom: 0px !important;
          overflow-wrap: break-word;


        @include devices(xsmall) {
          font-size: 1.6em !important;
        }
        }
      }
    }
  }
}
