.custom_scrollbar {
    overflow-y: auto;
    overflow-y: auto;
    width: 100%;
    height: auto;
        
    &::-webkit-scrollbar-thumb {
        background-color: var(--color-secondary-purple-200);
        outline: var(--border-width-hairline) solid var(--color-secondary-purple-200);
        border-radius: var(--border-radius-lg);
    }

    &::-webkit-scrollbar {
        width: 0.5em;
        
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
        margin: var(--spacing-squish-sm);
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--color-secondary-purple-300);
    }
}
