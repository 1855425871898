@import "../../../styles/mixin";

.h1 {
  font-size: var(--font-size-h1);
  font-weight: 700;
  line-height: 65px;

  @include devices(xsmall) {
    font-size: var(--font-size-h1-min);
    line-height: 55px;
  }

  @include devices(small) {
    font-size: var(--font-size-h1-min);
    line-height: 55px;
  }
}

.h2 {
  font-size: var(--font-size-h2);
  font-weight: 700;
  line-height: 65px;

  @include devices(xsmall) {
    font-size: var(--font-size-h2-min);
    line-height: 50px;
  }

  @include devices(small) {
    font-size: var(--font-size-h2-min);
    line-height: 50px;
  }
}

.h3 {
  font-size: var(--font-size-h3);
  font-weight: 700;
  line-height: 55px;

  @include devices(xsmall) {
    font-size: var(--font-size-h3-min);
    line-height: 35px;
  }

  @include devices(small) {
    font-size: var(--font-size-h3-min);
    line-height: 35px;
  }
}

.h4 {
  font-size: var(--font-size-h4);
  font-weight: 700;
  line-height: 40px;

  @include devices(xsmall) {
    font-size: var(--font-size-h4);
    line-height: 35px;
  }

  @include devices(small) {
    font-size: var(--font-size-h4);
    line-height: 35px;
  }
}

.h5 {
  font-size: var(--font-size-h5);
  font-weight: 700;
  line-height: 30px;

  @include devices(xsmall) {
    font-size: var(--font-size-h5-min);
    line-height: 25px;
  }

  @include devices(small) {
    font-size: var(--font-size-h5-min);
    line-height: 25px;
  }
}

.h6 {
  font-size: var(--font-size-h6);
  font-weight: 700;
  line-height: 20px;

  @include devices(xsmall) {
    font-size: var(--font-size-h5-min);
  }

  @include devices(small) {
    font-size: var(--font-size-h5-min);
  }
}

.heading__color-default {
  color: var(--color-blue-500);
}

.heading__color-primary {
  color: var(--color-primary-yellow-200);
}

.heading__color-secondary {
  color: var(--color-secondary-purple-300);
}

.heading__color-white {
  color: var(--color-write);
}
