@import "../../../styles/mixin";

.nav {
    z-index: 5;
    background: var(--color-secondary-purple-300);
    height: 95px;
    position: relative;
    padding: 0 42px !important;
    z-index: 9999;

    a:active {
        background: initial;
    }

    :global {
        a.active,
        a:hover {
            font-weight: 700;
        }
    }

    @include devices(xsmall) {
        max-height: 75px;

        width: 100%;
        
        top: 0;

        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    @include devices(small) {
        max-height: 75px;

        width: 100%;
        
        top: 0;

        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    @include devices(medium) {
        height: 43px;

        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .nav-actions {
        gap: var(--spacing-inset-nano);
        display: grid;
        justify-content: end;
        align-items: center;
        grid-template-columns: 115px 114px 210px 262px;
        column-gap: var(--spacing-stack-xs);

        a {
            color: var(--color-write);
            font-size: 15px;
            line-height: var(--spacing-inline-xxs);
            text-decoration: none;
        }

        @include devices(xsmall) {
            display: none;
        }

        @include devices(small) {
            display: none;
        }

        @include devices(medium) {
            display: none;
        }
    }

    .dropdown-menu-button {
        outline: 0;
        border: none;
        background-color: transparent;
        display: none;
        flex-direction: column;
        gap: 4px;

        @include devices(xsmall) {
            display: flex;
        }

        @include devices(small) {
            display: flex;
        }

        @include devices(medium) {
            display: flex;
        }

        img {
            width: 21px;
        }
    }

    .acess-app-button {
        width: 262px;
        height: 49px;
    }
}
