@import "../../../styles/mixin";

.modal-hidden {
    display: none !important;
}

.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: auto;
        max-height: calc(75svh);
        background: var(--color-secondary-purple-300);
        color: white;
        padding: 40px;
        border-radius: 8px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        min-width: calc(80%);
        min-height: calc(97svh);

        @include devices(xsmall) {
            width: 100%;
            height: 100%;
            overflow: auto;
            padding: 48px 8px;
        }

        @include devices(small) {
            width: 100%;
            height: 100%;
            overflow: auto;
            padding: 16px;
        }

        @include devices(medium) {
            width: 100%;
            height: 100%;
            padding: 32px;
        }

        &__close {
            position: absolute;
            background-color: white;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            height: 32px;
            border-radius: 100%;
            top: 16px;
            color: var(--color-secondary-purple-300);
            right: 16px;
            cursor: pointer;
            z-index: 9999;
        }
    }
}

.small {
    width: 100%;
    max-width: 380px;
}

.medium {
    width: 100%;
    max-width: 500px;
}

.large {
    width: 100%;
    max-width: 720px;
}

.xlarge {
    width: 100%;
    max-width: 1120px;

    @include devices(xxlarge) {
        max-width: 1520px;
    }
}
