@import "../../../styles/mixin";

.section-pacotes {
    display: flex;
    flex-direction: column;
    gap: 84px;
    padding: 0 32px;

    @include devices(xsmall) {
        gap: 40px;
    }

    @include devices(small) {
        gap: 40px;
    }


    div > h3 {
        font-size: 45px;
    }

    div > h5 {
        display: none
    }

    .select-plan-text {
        font-weight: normal;
        font-size: 35px !important;
        margin-top: var(--spacing-inline-sm);
        line-height: 45px;

        @include devices(xsmall) {
            font-size: 12px !important;
            line-height: 15.5px;
        }

        @include devices(small) {
            font-size: 12px !important;
            line-height: 15.5px;
        }
    }

    @include devices(xsmall) {
        gap: 28px;

        div > h3 {
            display: none;
        }

        div > h5 {
            display: block;
            margin-bottom: 0;
        }
    }

    @include devices(small) {
        gap: 28px;

        div > h3 {
            display: none;
        }

        div > h5 {
            display: block;
            margin-bottom: 0;
        }
    }

    .select-plan {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;

        .select-plan-switch {
            display: flex;
            gap: 27px;
            align-items: center;

            @include devices(xsmall) {
                gap: 16px;
            }

            .anual-plan-text, .semester-plan-text {
                font-weight: 400;
                opacity: .4;
                font-size: 25px !important;

                @include devices(xsmall) {
                    font-size: 14px !important;
                }

                @include devices(small) {
                    font-size: 14px !important;
                }
            }

            .anual-plan-text {
                width: 75px;

                @include devices(xsmall) {
                    width: 40px !important;
                }

                @include devices(small) {
                    width: 40px !important;
                }
            }

            .semester-plan-text {
                width: 120px;

                @include devices(xsmall) {
                    width: 67px !important;
                }

                @include devices(small) {
                    width: 67px !important;
                }
            }

            .anual-plan-text.selected, .semester-plan-text.selected {
                font-weight: 700;
                opacity: 1;
            }

            p {
                margin-bottom: 0;
            }

            input {
                width: 130px;
                height: 60px;

                @include devices(xsmall) {
                    width: 48px !important;
                    height: 24px !important;
                }

                @include devices(small) {
                    width: 48px !important;
                    height: 24px !important;
                }
            }

            :global {
                .form-check-input {
                    background-color: var(--color-secondary-purple-300) !important;
                    border-color: #0d6efd;
                }

                .form-switch, .form-check-input {
                      outline: 0 !important;
                      border: none;
                  }

                  .form-switch .form-check-input {
                    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
                  }
            }
        }
    }

    .plans-cards {
        display: flex;
        gap: 8px;
        justify-content: center;
    }

    .action-buttons {
        display: flex;
        justify-content: center;
        gap: var(--spacing-inset-xxs);

        > div {
            width: max-content;

            button {
                padding: 12px 75px;
            }
        }

        @include devices(xsmall) {
            flex-direction: column;
            align-items: center;

            > div {
                button {
                    width: 300px;
                    font-size: 12px;
                    padding: 12px 0;
                }
            }
        }

        @include devices(small) {
            flex-direction: column;
            align-items: center;

            > div {
                button {
                    width: 300px;
                    font-size: 12px;
                    padding: 12px 0;
                }
            }
        }
    }
}
