@import '../../../styles/mixin';

.section_conecte_se {
    padding: 0;

    article {
        padding: var(--spacing-topbottom-lg) var(--spacing-leftright-xs);
    }
    
    &__article {
        display: flex;
        justify-content: center;
        align-items: center;
        
        & > h4 {
            text-align: center;
            max-width: 740px;

            @include devices(xsmall){
                font-size: var(--font-size-h6);
                line-height: 25px;
            }

            @include devices(small) {
                font-size: var(--font-size-h6);
                line-height: 25px;
            }
        }
    }

    &__article_vidas {
        height: 175px;

        background-color: var(--color-secondary-purple-300);
        
        display: flex;
        justify-content: center;
        align-items: center;
    
        &__container {
            display: flex;
            justify-content: center;
            align-items: flex-end;
        
            gap: var(--spacing-stack-nano);
            
            &__title, &__sub_title {
                line-height: normal;
                margin: 0;
            }
            
            @include devices(small) {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                gap: 0;
            }
            
            @include devices(xsmall) {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                gap: 0;
                
                width: 75%;
                
            }
        }
        
    }

    &__article_lista {
        background-color: var(--color-support-bg-gray);       
        
        display: flex;
        justify-content: center;
        
        &__container {
            max-width: 1140px;
            width: 100%;
    
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: var(--spacing-topbottom-lg) 0;
                        
            &__card {
                display: grid;
                grid-template-columns: 1fr 4fr;
                
                &__title {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include devices(xsmall) {
                        & > img {
                            width: 30px;
                        }
                    }

                    @include devices(small) {
                        & > img {
                            width: 30px;
                        }
                    }
                }

                &__body {
                    & > h4 {
                        margin: 0;
                    }

                    @include devices(xsmall) {
                        text-align: center;

                        & > h4 {
                            font-size: var(--font-size-h6);
                        }

                        & > span {
                            font-size: var(--font-size-body-text-3);
                            line-height: 10px;
                        }
                    }

                    @include devices(small) {
                        text-align: center;

                        & > h4 {
                            font-size: var(--font-size-h6);
                        }

                        & > span {
                            font-size: var(--font-size-body-text-3);
                            line-height: 10px;
                        }
                    }
                }

                @include devices(xsmall) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }

                @include devices(small) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }
            }
        }
        
    }
}