
.collapse {
    border: var(--border-width-hairline) solid var(--color-support-bg-gray);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-squish-xs);
}

.collapse-background {
    background-color: var(--color-support-bg-gray);
}

.collapse
    > .collapse-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    gap: var(--spacing-inline-nano);

}

.collapse
    > .collapse-header
    > .button-arrow {
    border-radius: var(--border-radius-circle);
    padding: var(--spacing-inset-nano);
    background: var(--color-secondary-purple-300);

    width: 35px;
    height: 35px;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 15px;
        height: 10px;
    }
}

.collapse
    > .collapse-header
    > .img-360 {
    transform: rotate(180deg);
}

.collapse
    > .collapse-body {
    padding-top: var(--spacing-topbottom-sm);

    display: flex;
    flex-direction: column;
    gap: var(--spacing-inline-xxxs);
}