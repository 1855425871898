@import '../../../styles/mixin';

.section_tecnologia {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    
    &__container {
        max-width: 1140px;
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;

        gap: var(--spacing-topbottom-md) 0;
        padding: 0 var(--spacing-leftright-xs);
        
        &__title > h3 {
            @include devices(xsmall){
                font-size: var(--font-size-h5);
                line-height: 30px;
            }

            @include devices(small) {
                font-size: var(--font-size-h5);
                line-height: 30px;
            }
        }

        &__lista {
            width: 100%;

            display: flex;
            flex-direction: column;
            align-items: center;
            
            gap: var(--spacing-topbottom-md) 0;
    
            &__card {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                
                padding: var(--spacing-squish-lg);

                box-shadow: var(--shadow-level-1) var(--color-secondary-purple-100);
                border-radius: var(--border-radius-lg);
                border: var(--border-width-thin) solid var(--color-secondary-purple-100);

                &__content {
                    width: 100%;
                    max-width: 680px;

                    & > h4 {
                        
                        @include devices(xsmall){
                            font-size: var(--font-size-h5);
                            line-height: 30px;
                        }
            
                        @include devices(small) {
                            font-size: var(--font-size-h5);
                            line-height: 30px;
                        }
                    }
    
                    & > span {
                        @include devices(xsmall){
                            font-size: var(--font-size-body-text-2-min);
                            
                        }
            
                        @include devices(small) {
                            font-size: var(--font-size-h5);
                            line-height: 30px;
                        }
                    }
                }
            }

        }
    }
}