.dropdown-menu {
  position: absolute;
  background-color: var(--color-write);
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 9999;

  :global {
    a {
      text-decoration: none;
    }

    a.active {
      p {
        font-weight: 700;
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;

    li {
      text-align: end;
      border-bottom: 1px solid #F4F4F5;
      padding: 12px;

      p {
        font-size: 12px;
        margin-bottom: 0;
      }

      img {
        width: 15px;
        height: 15px;
      }
    }
  }
}