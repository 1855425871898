@import "../../../styles/mixin";

.section-comece-jornada {
    display: flex;
    flex-direction: column;
    gap: 84px;
    align-items: center;

    @include devices(xsmall) {
        gap: 30px;
    }

    @include devices(small) {
        gap: 30px;
    }

    h2 {
        font-size: 65px;
        text-align: center;

        @include devices(xsmall) {
            font-size: 25px;
            line-height: 25px;
        }

        @include devices(small) {
            font-size: 25px;
            line-height: 25px;
        }
    }

    h3 {
        font-weight: 500;

        @include devices(xsmall) {
            font-size: 15px;
            line-height: 16px;
        }

        @include devices(small) {
            font-size: 15px;
            line-height: 16px;
        }
    }

    .action-buttons {
        display: flex;
        gap: 24px;

        figure {
            cursor: pointer;
        }

        @include devices(xsmall) {
            gap: 8px;
        }

        @include devices(small) {
            gap: 8px;
        }
    }
}