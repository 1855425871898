@import "../../../styles/mixin";

.section-simule-pacote {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: var(--spacing-topbottom-md);
}

.section-simule-pacote > .section-simule-pacote_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    align-content: center;

    @include devices(xxlarge) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    @include devices(xlarge) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    @include devices(large) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

.section-simule-pacote > .section-simule-pacote_container > .section-simule-pacote_container__title {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-inline-xs);

    & > small {
        width: 78%;

        @include devices(medium) {
            width: 100%;
        }

        @include devices(small) {
            width: 100%;
        }

        @include devices(xsmall) {
            width: 100%;
        }
    }

    span {
        color: var(--color-support-purple);
    }

    .section-simule-pacote_container__title__heading {
        width: 100%;

        @include devices(medium) {
            width: calc(100% - 5vh);
        }

        @include devices(small) {
            width: 100%;
        }

        @include devices(xsmall) {
            width: 100%;
        }
    }
}

.section-simule-pacote
    > .section-simule-pacote_container
    > .section-simule-pacote_container__title
    > .section-simule-pacote_container__image {
    width: 100%;
    height: auto;

    @include devices(medium) {
        display: none;
    }

    @include devices(small) {
        display: none;
    }

    @include devices(xsmall) {
        display: none;
    }
}

.section-simule-pacote > .section-simule-pacote_container > .section-simule-pacote_container__content-card {
    gap: var(--spacing-inline-xxxs);

    text-align: center;

    flex-direction: column;
    display: flex;
    align-items: baseline;
    align-content: center;
    justify-content: center;

    @include devices(xxlarge) {
        padding-left: var(--spacing-leftright-sm);
    }

    @include devices(xlarge) {
        padding-left: var(--spacing-leftright-sm);
    }

    @include devices(large) {
        padding-left: var(--spacing-leftright-sm);
    }

    @include devices(small) {
        padding: var(--spacing-topbottom-md) 0;
    }

    @include devices(xsmall) {
        padding: var(--spacing-topbottom-md) 0;
    }
}

.section-simule-pacote
    > .section-simule-pacote_container
    > .section-simule-pacote_container__content-card
    > .section-simule-pacote_container__content-card__card {
    display: flex;
    align-items: center;
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--color-secondary-purple-300);
    padding: var(--spacing-stack-xxxs);

    width: 100%;

    @include devices(xsmall) {
        height: 100%;
    }

    @include devices(small) {
        height: 100%;
    }

    & > div {
        width: 100%;
    }

    &:hover:not(.section-simule-pacote_container__content-card__card--selected) {
        cursor: pointer;
        background-color: var(--color-secondary-purple-300);
        color: white;

        small {
            color: white;
        }

        h6 {
            color: white;
        }

        @include devices(xsmall) {
            background-color: white;
            small {
                color: var(--color-secondary-purple-300) !important;
            }
            
            h6 {
                color: var(--color-secondary-purple-300) !important;
            }
        }
    
        @include devices(small) {
            background-color: white;
            small {
                color: var(--color-secondary-purple-300) !important;
            }
            
            h6 {
                color: var(--color-secondary-purple-300) !important;
            }
        }
    
    }
    
    &.section-simule-pacote_container__content-card__card--selected {
        background-color: var(--color-secondary-purple-300);
        color: white;
    
        small {
            color: white;
        }
    
        h6 {
            color: white;
        }
    }
}

.section-simule-pacote
    > .section-simule-pacote_container
    > .section-simule-pacote_container__content-card
    > .section-simule-pacote_container__content-card__card-form {
    margin-top: var(--spacing-topbottom-sm);
    gap: var(--spacing-inline-xxs);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include devices(medium) {
        width: 100%;
    }

    @include devices(small) {
        width: 100%;
    }

    @include devices(xsmall) {
        width: 100%;
    }

    .heading {
        margin: 0;

        @include devices(xsmall) {
            font-size: var(--font-size-h5-min);
            line-height: 20px;
        }

        @include devices(small) {
            font-size: var(--font-size-h5-min);
            line-height: 20px;
        }
    }
}

.section-simule-pacote
    > .section-simule-pacote_container
    > .section-simule-pacote_container__content-card
    > .section-simule-pacote_container__content-card__card-form
    > .section-simule-pacote_container__content-card__card-form__forms {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: var(--spacing-inline-xxxs);

    width: 100%;

    fieldset {
        width: calc(100% - 20vh);
        @include devices(medium) {
            width: 100%;
        }

        @include devices(small) {
            width: 100%;
        }

        @include devices(xsmall) {
            width: 100%;
        }
    }

    @include devices(xxlarge) {
        width: calc(100% - 5vh);
    }

    @include devices(xlarge) {
        width: calc(100% - 5vh);
    }

    @include devices(large) {
        width: calc(100% - 5vh);
    }
}

.span-error {
    font-weight: 600;

    color: var(--color-support-red);
}
