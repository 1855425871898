@import '../../../styles/mixin';

.section_main_rede {
    contain: layout;

    height: calc(110vh - 20vh);
    width: 100%;

    padding: 0;
    
    @include devices(xsmall) {
        width: -webkit-fill-available;
        height: -webkit-fill-available;    
    }

    @include devices(small) {
        width: -webkit-fill-available;
        height: -webkit-fill-available;    
    }

    &__container {
        padding: 0 var(--spacing-leftright-xs);
        gap: var(--spacing-stack-xs);

        width: 100%;
        height: 100%;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        
        @include devices(xsmall) {
            padding: var(--spacing-inline-xl) var(--spacing-leftright-xs);

            width: -webkit-fill-available;
            height: -webkit-fill-available;    

            object-fit: cover;
            contain: layout;
        }

        @include devices(small) {
            padding: var(--spacing-inline-xl) var(--spacing-leftright-xs);

            width: -webkit-fill-available;
            height: -webkit-fill-available;    

            object-fit: cover;
            contain: layout;
        }

        display: flex;
        flex-direction: column;
        justify-content: center;

        &__title, &__body {
            max-width: 690px
        }

        &__footer {
            display: flex;
            align-items: center;

            gap: var(--spacing-stack-nano);

            cursor: pointer;

            & > i {
                height: 21px;
                width: 21px;

                display: flex;
                justify-content: center;
                align-items: center;

                background-color: var(--color-primary-yellow-200);

                border-radius: var(--border-radius-circle);

                & > img {
                    max-width: 8px;
                    max-height: 8px;
                }
            } 
        }
    }
}