@import '../../../../styles/mixin';

.figure-icon-saude {
    padding: 0;
    margin: 0;
    display: flex;
    flex: 0 0 auto;
    align-items: center;

    @include devices(xsmall) {
        width: 32%;
    }
    @include devices(small) {
        width: 32%;
    }

    small {
        color: var(--color-support-purple-2);
    }
}

.column {
    flex-direction: column;
}

.row {
    flex-direction: row;
    gap: var(--spacing-leftright-xs);
}
