@import '../../../styles/mixin';

.section-main {
  margin-bottom: var(--spacing-topbottom-sm);

  @include devices(medium) {
    margin-top: 45px;
    margin-bottom: var(--spacing-topbottom-sm);
  }

  @include devices(xsmall) {
    margin-top: 45px;
    margin-bottom: none;
  }

  @include devices(small) {
    margin-top: 45px;
    margin-bottom: none;
  }
}

.section-main > .section-main__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85vh;

  @include devices(medium) {
    height: auto;
  }

  @include devices(small) {
    height: auto;
  }

  @include devices(xsmall) {
    height: auto;
  }
}

.section-main > .section-main__container > .section-main__container--group-card {
  width: 100%;
  max-height: 220px;
  height: 100%;

  @include devices(small) {
    max-height: 190px;
  }

  @include devices(xsmall) {
    max-height: 190px;
  }

  @include devices(medium) {
    max-height: 190px;
  }
}

.section-main > .section-main__container 
  > .section-main__container--group-card 
  > .section-main__container--card {
  background-color: var(--color-support-bg-gray);
  border-radius: var(--border-radius-lg);
  height: 100%;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  @include devices(medium) {
    margin-top: var(--spacing-stack-xs);

    span {
      display: block;
      font-size: var(--font-size-sub-title-2-min) !important;
      line-height: 32px;
      margin-bottom: 16px;
    }
  }

  @include devices(small) {
    margin-top: var(--spacing-stack-xxxs);

    span {
      font-size: var(--font-size-body-text-1-min) !important;
      line-height: 20px;
    }
  }
  @include devices(xsmall) {
    margin-top: var(--spacing-stack-xxxs);

    display: flex;
    span {
      font-size: var(--font-size-body-text-1-min) !important;
      line-height: 20px;
    }
  }

  .section-main__container--card-figure {
    display: none;

    @include devices(small) {
      padding: var(--spacing-stack-xxxs);
      width: 100%;
    }
    @include devices(xsmall) {
      padding: var(--spacing-stack-xxxs);
      width: 100%;
    }
  }
}

.section-main
  > .section-main__container
  > .section-main__container--group-card
  > .section-main__container--card
  > .section-main__container--card-subtitle {
  height: 100%;
  width: 100%;
  z-index: 5;
  
  padding: var(--spacing-stack-xs);
  display: grid;
  grid-template-columns: 2fr 3fr;

  & > span {
    gap: var(--spacing-inline-xxxs);
    flex-direction: column;
    display: flex;
  }

  @include devices(medium) {
    padding: var(--spacing-stack-xs);
    grid-template-columns: auto;
    width: 100%;
  }
  @include devices(small) {
    padding: var(--spacing-stack-xxxs);
    grid-template-columns: auto;
    width: 100%;
  }
  @include devices(xsmall) {
    padding: var(--spacing-stack-xxxs);
    grid-template-columns: auto;
    width: 100%;
  }
}

.section-main > .section-main__container > .section-main__container_content {
  display: flex;
  align-items: center;
  height: 100%;

  @include devices(medium) {
    height: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  @include devices(small) {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  @include devices(xsmall) {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

.section-main
  > .section-main__container
  > .section-main__container_content
  > .section-main__container_content--heanding {
  max-width: 480px;
  max-width: 480px;
  z-index: 5;

  span {
    color: var(--color-support-purple);
  }

  @include devices(xsmall) {
    font-size: var(--font-size-h3-min) !important;
    line-height: 35px;
  }

  @include devices(small) {
    font-size: var(--font-size-h3-min) !important;
    line-height: 50px;
  }

  @include devices(medium) {
    font-size: var(--font-size-h2-min) !important;
    line-height: 50px;
    max-width: 100%;
  }
}

.section-main > .section-main__container > .section-main__container_content > .section-main__container_content--figure {
  figure {
    z-index: 1;
    max-height: 700px;
    height: 100%;

    @include devices(small) {
      display: none;
    }

    @include devices(medium) {
      display: none;
    }

    @include devices(xsmall) {
      display: none;
    }
  }

  @include devices(medium) {
    width: 100%;
  }

  @include devices(small) {
    width: 100%;
  }

  @include devices(xsmall) {
    width: 100%;
  }
}

.section-main__container--card-figure {
  display: none;
  margin-top: 16px;

  @include devices(medium) {
    display: flex;
    justify-content: center;
  }

  @include devices(small) {
    display: flex;
    justify-content: center;
  }

  @include devices(xsmall) {
    display: flex;
    justify-content: center;
  }
}
