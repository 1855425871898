@import '../../../../styles/mixin';

.figure-download {
    display: flex;
    gap: var(--spacing-stack-nano);
    margin: 0;
}

.figure-download img {
  width: 142px;
  height: 42px;

  cursor: pointer;

  @include devices(small) {
    width: 70px;
    height: 26px;
  }

  @include devices(xsmall) {
    width: 70px;
    height: 26px;
    margin-top: 16px;
  }
}

.figure-download__column {
  flex-direction: column;
}

.figure-download__row {
    flex-direction: row;
}
