@import "../../../../styles/mixin";

.figure-download > img {
  width: 25px;
  height: 25px;

  @include devices(xsmall) {
    width: 12px;
    height: 12px;
}

  cursor: pointer;
}
