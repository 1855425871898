@import '../../../../styles/mixin';

.vantagens-card {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-stack-xs);
  color: var(--color-secondary-purple-300);

  @include devices(xsmall) {
    flex-direction: column;
  }
  @include devices(small) {
    flex-direction: column;
  }

  @include devices(medium) {
    flex-direction: column;
  }

  & > img {
    height: 300px;
    width: auto;
    border-radius: 10px;
    margin-bottom: var(--spacing-stack-xs);
    margin-right: var(--spacing-stack-lg);

    @include devices(xsmall) {
      height: auto;
      min-width: 200px;
      max-width: 98%;
      margin-right: 0px;
    }

    @include devices(small) {
      height: auto;
      width: 100%;
      max-width: 100%;
      margin-right: 0px;
    }

    @include devices(medium) {
      height: auto;
      width: 100%;
      max-width: 100%;
      margin-right: 0px;
    }
  }

  &__content {
    flex: 3;
    box-sizing: border-box;
    width: 100%;
    min-width: 320px;

    @include devices(xsmall) {
      min-width: 200px;
    }

    @include devices(large) {
      min-width: 600px;
    }

    flex-direction: column;
    justify-content: center;

    &> p {
      color: var(--color-secondary-purple-300);
      font-weight: 400;
      font-size: 1.9em;
      line-height: 35px;
      margin-top: var(--spacing-stack-xs);

      @include devices(xsmall) {
        font-size: 1.2em;
      }
      @include devices(xsmall) {
        font-size: 1.4em;
      }
    }

    & > strong {
      color: var(--color-secondary-purple-300);
      font-weight: 700;
      font-size: 2.3em !important;
      margin-top: var(--spacing-stack-md);

      @include devices(xsmall) {
        font-size: 1.6em !important;
      }

      @include devices(small) {
        font-size: 1.8em !important;
      }
    }
  }
}
