.plan-card {
  border: var(--border-width-hairline) solid #9f9fa7;
  border-radius: 15px;
  padding: 30px 16px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-inset-xxs);

  min-height: 400px;

  .plan-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .plan-name {
      margin-bottom: 0 !important;
      font-weight: 700;
    }

    .plan-price {
      display: flex;
      flex-direction: column;
      align-items: center;

      small {
        font-size: 12px;
      }

      span {
        font-weight: 700;
        font-size: 18px;
      }

      small:last-of-type {
        width: max-content;
      }
    }
  }

  .plan-details {
    .plan-funcionalidade-text {
      font-weight: 400;
    }

    ul {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      li {
        &.not-included {
          opacity: 0.4;
        }

        display: flex;
        gap: 12px;
        align-items: center;

        small {
          font-size: 12px;
          list-style: 0;
          margin-bottom: -2px;
        }
      }
    }
  }

  &:hover,
  &:active {
    background-color: var(--color-secondary-purple-300);
    cursor: pointer;
    
    p,
    small {
      color: white;
    }

    svg {
      path {
        fill: white;
      }
    }
  }
}
