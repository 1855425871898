@import "../../../styles/mixin";

.sub-title-1 {
	font-size: 30px;
	font-weight: 500;
	line-height: 35px;

	@include devices(xsmall) {
		font-size: 22px;
		line-height: 25px;
	}

	@include devices(small) {
		font-size: 22px;
		line-height: 25px;
	}
}

.sub-title-2 {
	font-size: 22px;
	font-weight: 500;
	line-height: 30px;

	@include devices(xsmall) {
		font-size: 20px;
	}

	@include devices(small) {
		font-size: 20px;
	}
}

.body-text-1 {
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;

	@include devices(xsmall) {
		font-size: 18px;
	}

	@include devices(small) {
		font-size: 18px;
	}
}

.body-text-2 {
	font-size: 18px;
	font-weight: 500;
	line-height: 25px;

	@include devices(xsmall) {
		font-size: 16px;
	}

	@include devices(small) {
		font-size: 16px;
	}
}

.body-text-3 {
	font-size: 14px;
	font-weight: 500;
	line-height: 15px;
}

.body-text-4 {
	font-size: 30px;
	line-height: 35px;

	@include devices(xsmall) {
		font-size: 16px;
	}

	@include devices(small) {
		font-size: 18px;
	}
}

.body-text-0 {
	font-weight: 500;
}

.text__color-default {
	color: var(--color-font-black);
}

.text__color-primary {
	color: var(--color-primary-yellow-200);
}

.text__color-secondary {
	color: var(--color-secondary-purple-300);
}

.text__color-secondary-support {
	color: var(--color-support-purple);
}

.text__color-white {
	color: var(--color-write);
}

.small {
	font-size: var(--font-size-body-text-3);
}
