@import "../../../../styles/mixin";

.modal-hidden {
    display: none !important;
}

.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        width: auto;
        height: auto;
        max-height: calc(90svh);
        min-width: calc(80%);
        min-height: calc(97svh);

        background: var(--color-secondary-purple-300);
        color: white;
        padding: 40px;
        border-radius: 8px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;  

        @include devices(xsmall) {
            width: 100%;
            height: 100%;
            padding: 48px 8px;
        }

        @include devices(small) {
            width: 100%;
            height: 100%;
            padding: 16px;
        }

        @include devices(medium) {
            width: 100%;
            height: 100%;
            padding: 32px;
        }

        &__header {
            max-width: 600px;
            h2 {
                font-size: 28px;
                font-weight: bold;
                margin-bottom: 24px;
            }

            p {
                font-size: 16px;
                margin-bottom: 24px;
            }
        }

        &__body {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 24px;
            overflow-y: auto;

            @include devices(xsmall) {
                width: 100%;
                img {
                    display: none;
                }
            }

            @include devices(small) {
                img {
                    display: none;
                }
            }

            @include devices(medium) {
                img {
                    display: none;
                }
            }

            img {
                width: 50%;
            }

            :global {
                form {
                    display: grid;
                    place-content: center;

                    @include devices(xsmall) {
                        width: 100%;
                        padding: 0;
                        display: flex;
                        flex-direction: column;
                    }

                    @include devices(small) {
                        width: 100%;
                        padding: 0;
                        display: flex;
                        flex-direction: column;
                    }
                }

                .form-control {
                    display: grid;
                    grid-template-columns: 1em auto;
                    gap: 0.5em;
                }

                .form-control + .form-control {
                    margin-top: 1em;
                }

                .form-control--disabled {
                    color: var(--form-control-disabled);
                    cursor: not-allowed;
                }
            }

            &__form {
                min-height: 90%;
                width: 40%;
                padding-left: 32px;

                label {
                    text-align: left;
                    margin: 8px 0px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                }

                input[type="text"] {
                    width: 360px;

                    @include devices(xsmall) {
                        width: 100%;
                    }

                    @include devices(small) {
                        width: 320px;
                    }
                }

                .check {
                    display: flex;
                    align-items: center;
                    margin-top: 16px;
                    margin-bottom: 8px;

                    label {
                        margin-left: 8px;
                    }
                }

                button {
                    margin-top: 8px;
                }
            }
        }

        &__close {
            position: absolute;
            background-color: white;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            height: 32px;
            border-radius: 100%;
            top: 16px;
            color: var(--color-secondary-purple-300);
            right: 16px;
            cursor: pointer;
            z-index: 9999;
        }
    }
}
