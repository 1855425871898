@import '../../../styles/mixin';

.section-main-empresas {
  box-sizing: border-box;
  margin-top: var(--spacing-stack-xxl);
  width: 100%;
  display: flex;
  color: var(--color-secondary-purple-300);
  font-size: 18px;

  @include devices(xsmall) {
    margin-top: var(--spacing-stack-xs);
    font-size: 10px;
  }

  @include devices(small) {
    margin-top: var(--spacing-stack-sm);
    font-size: 14px;
  }

  @include devices(medium) {
    margin-top: var(--spacing-stack-md);
    font-size: 16px;
  }

  h2 {
    font-size: 3.7em;
    line-height: 1.1em;
  }

  span {
    color: var(--color-primary);
  }
  p {
    color: var(--color-secondary-purple-300);
    margin-top: var(--spacing-stack-xl);

    @include devices(xsmall) {
      font-size: 1.5rem !important;
      margin-top: var(--spacing-stack-xs);
    }

    @include devices(small) {
      font-size: 1.5rem !important;
      margin-top: var(--spacing-stack-xs);
    }
  }

  &__container {
    width: 100%;
    padding: 48px 0px;

    .section-main-empresas__action {
      position: static;
      display: none;
      margin: 0px 50%;
      transform: translateX(-50%);
      min-width: 300px;
      font-size: 16px;

      p {
        font-size: 2.3em;
        margin-bottom: var(--spacing-stack-xs);
      }

      button {
        font-size: 2em;
      }

      @include devices(xsmall) {
        margin: 0px 50%;
        font-size: 10px;
        display: block;
      }

      @include devices(small) {
        margin: 0px 60%;
        font-size: 12px;
        display: block;
      }

      @include devices(medium) {
        display: block;
      }
    }
  }

  &__figure {
    box-sizing: border-box;
    background-image: url('~/public/images/img-print-celular-empresas.png');
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 50% center;

    display: none;
    position: relative;
    align-items: center;
    min-width: 50%;

    @include devices(large) {
      display: flex;
      background-size: auto 75%;
    }

    @include devices(xlarge) {
      display: flex;
    }

    @include devices(xxlarge) {
      display: flex;
    }
  }
}

.section-main-empresas__action {
  box-sizing: border-box;
  position: absolute;
  right: -10px;
  font-weight: 700;
  width: auto;


  p {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 13px;
    color: var(--color-secondary-purple-300);
  }

  @include devices(large) {
    right: -15%;
  }

  @include devices(xlarge) {
    right: -5%;
  }

  button {
    display: flex;
    border: none;
    color: var(--color-support-purple);
    background: none;

    font-size: 1.3rem;
    font-weight: 700;
    line-height: 20px;
    padding: 0px;

    span {
      display: flex;
      margin-left: 12px;
      width: 30px;
      height: 30px;
      display: block;

      font-weight: 700;
      line-height: 24px;
      border-radius: 24px;
      background-color: var(--color-support-purple);

      img {
        margin-top: 4px;
        width: 12px;
        height: 12px;
      }
    }
  }
}
