@import "../../../../styles/mixin";

.figure__image-capa > img {
  z-index: 0;

  @include devices(xxlarge) {
    min-width: 580px;
    max-width: 700px;
    max-height: 780px;
    min-height: 700px;
    width: 100%;
    height: 100%;

    bottom: 14px;
    margin-left: 100px;;

    position: absolute;
  }

  @include devices(xlarge) {
    max-width: 440px;
    max-height: 500px;
    width: 100%;
    height: 100%;

    bottom: 1.7px;
    right: 90px;
    position: absolute;
  }

  @include devices(large) {
    max-width: 500px;
    max-height: 550px;
    width: 100%;
    height: 100%;

    bottom: 1.7px;
    right: 140px;
    position: absolute;
  }

  @include devices(medium) {
    margin-top: 32px;
    max-width: 450px;
    max-height: auto;
    width: 100%;
    height: 100%;
  }

  @include devices(small) {
    max-width: 330px;
    max-height: 460px;
    width: 100%;
    height: 100%;
  }

  @include devices(xsmall) {
    width: 230px;
    height: 350px;
  }
}
