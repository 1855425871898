@import "../../../../styles/mixin";

.result-modal {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--spacing-stack-nano);

    @include devices(xsmall) {
        padding: 32px;
        flex-direction: column;
    }

    @include devices(small) {
        padding: 32px;
        flex-direction: column;
    }

    @include devices(medium) {
        padding: 32px;
        flex-direction: column;
    }

    &__body {
        &__title {
            h4 {
                color: white;
            }

            h4:first-child {
                opacity: var(--opacity-level-light);
            }
            
            h4 {
                &:nth-of-type(2) {
                    opacity: var(--opacity-level-intense);
                }
            }

        }
        
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: var(--spacing-stack-nano);
        width: 70%;
        span {
            color: var(--color-primary);
        }
        flex: 2;

        @include devices(xsmall) {
            width: auto;
        }
    
        @include devices(small) {
            width: auto;
        }
    
        @include devices(medium) {
            width: auto;
        }
    }

    img {
        min-width: 200px;
        max-height: 250px;
        flex: 1;
    }
}


.modal {
    min-width: 65%;
}