.container {
  position: relative;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  footer {
    background: var(--color-secondary-purple-300);
    li span {
      color: #fff;
    }
  }
}
.header_container {
  background: var(--color-secondary-purple-300);
}

.content {
  max-width: 753px;
  width: 100%;
  margin: 0 auto 0 42px;
  position: relative;
  h1 {
    margin-top: 122px;
    margin-bottom: 82px;
    color: var(--color-secondary-purple-300);
    font-size: 48px;
    line-height: 58px;
    font-weight: 700;
  }

  h2 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;

    color: var(--color-secondary-purple-300);
    margin: 32px 0;
  }

  p,
  li {
    font-size: 14px;
    line-height: 24px;
    color: var(--color-secondary-purple-300);
    margin-top: 32px;
  }

  div {
    padding-left: 86px;
    margin-top: 32px;

    div {
      margin-left: 24px;
    }
  }

  ul {
    list-style: none;
    margin: 32px 0;
    padding: 0;
  }

  h3 {
    font-size: 20px;
    line-height: 24px;
    color: var(--color-secondary-purple-300);
    margin: 32px 0;
  }

  @media (max-width: 768px) {
    margin: 0 auto;
    padding: 0 29px;
    div {
      padding-left: 36px;
    }
  }
}

.images_right {
  img {
    &:first-child {
      position: absolute;
      top: 150px;
      right: 0;
    }

    &:last-child {
      position: absolute;
      bottom: 50px;
      right: 0;
    }
  }

  @media (max-width: 1162px) {
    display: none;
  }
}
