@import '../../../styles/mixin';

.section-divider {
  font-size: 16px;
  
  @function get-margin-left($bootstrap-container) {
    $margin-calc: calc(100vw - #{$bootstrap-container});
    $margin-left: calc(#{$margin-calc} / 2 * -1);
    @return #{$margin-left};
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100vw;
  background: var(--color-secondary-purple-200);
  color: var(--color-secondary-purple-300);

  box-sizing: border-box;
  padding: var(--spacing-stack-lg) 10%;
  margin: var(--spacing-stack-md) 0px var(--spacing-stack-md) get-margin-left(1280px);

  @include devices(xlarge) {
    margin: var(--spacing-stack-md) 0px var(--spacing-stack-md) get-margin-left(1285px);
  }

  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    margin: var(--spacing-stack-md) 0px var(--spacing-stack-md) get-margin-left(1105px);
  }

  @include devices(large) {
    margin: var(--spacing-stack-md) 0px var(--spacing-stack-md) get-margin-left(925px);
  }

  @include devices(medium) {
    font-size: 14px;
    margin: var(--spacing-stack-md) 0px var(--spacing-stack-md) get-margin-left(685px);
  }

  @include devices(xsmall) {
    margin: 0px;
    width: 95vw;
    font-size: 10px;
  }

  @include devices(small) {
    font-size: 12px;
    margin: var(--spacing-stack-md) 0px var(--spacing-stack-md) get-margin-left(505px);
  }

  & > p {
    max-width: 1200px;
    margin: auto 0px;
    font-weight: 500;
    font-size: 2.2em;
    line-height: 35px;
    text-align: center;
    color: var(--color-secondary-purple-300);

    span {
      color: var(--color-support-purple);
    }
  }
}
