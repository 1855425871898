@mixin devices ($breakpoint) {

    @if $breakpoint == xxlarge {
        @media only screen and (min-width: 1800px)  {
            @content;
        }
    }

    @if $breakpoint == xlarge {
        @media only screen and (min-width: 1200px) and (max-width: 1799.99px) {
            @content;
        }
    }

    @if $breakpoint == large {
        @media only screen and (min-width: 992px) and (max-width: 1199.98px)  {
            @content;
        }
    }

    @if $breakpoint == medium {
        @media only screen and (min-width: 767.99px) and (max-width: 991.98px) {
            @content;
        }
    }

    @if $breakpoint == small {
        @media only screen and (min-width: 576px) and (max-width: 767.98px) {
            @content;
        }
    }

    @if $breakpoint == xsmall {
        @media only screen and (max-width: 575.98px) {
            @content;
        }
    }
}
