@import '../../../styles/mixin';

.section-triade {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-inline-xl);

  & > h4 {
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 30px;
    text-align: center;

    @include devices(xsmall) {
      font-size: 1.4rem;
    }

    @include devices(small) {
      font-size: 1.6rem;
    }
  }

  & > img {
    width: 590px;
  }
}
