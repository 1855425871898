.textarea {
    white-space: pre-line;
    
    background-color: #F8F8F6 !important;
    border-color: #F8F8F6 !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    padding: var(--spacing-squish-nano) !important;
    gap: var(--spacing-inset-nano);

    border-radius: var(--border-radius-md) !important;
}

.is-valid {
    box-shadow: 0 0 0 0.15rem var(--color-green-300) !important;
}

.is-invalid {
    box-shadow: 0 0 0 0.20rem var(--color-support-red) !important;
}