@import "../../../styles/mixin";

.footer {
    min-height: 360px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    background: var(--color-secondary-purple-300);
    width: 100%;
    padding: 64px 90px !important;
    align-items: start;

    a {
        text-decoration: none;
        cursor: pointer;

        &:active {
            background: initial;
        }
    }

    @include devices(xsmall) {
        padding: var(--spacing-leftright-sm) !important;
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content max-content max-content;
        gap: 31px;
    }

    @include devices(small) {
        padding: 20px var(--spacing-leftright-sm);
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content max-content max-content;
        gap: 31px;
    }

    .logo {
        img {
            width: 250px;
            height: 111px;

            @include devices(xsmall) {
                width: 101px;
                height: 42px;
            }

            @include devices(small) {
                width: 101px;
                height: 42px;
            }
        }
    }

    .footer__container-links {
        display: flex;
        flex-direction: column;
        gap: 8px;

        li {
            cursor: pointer;
            font-weight: 500;

            span {
                @include devices(xsmall) {
                   font-size: 12px !important;
                   line-height: 25px;
                }

                @include devices(small) {
                    font-size: 12px !important;
                    line-height: 25px;
                 }
            }
        }
    }

    .footer__container-social-medias {
        display: flex;
        gap: 18px;

        @include devices(xsmall) {
            grid-row: 2;
        }

        @include devices(small) {
            grid-row: 2;
        }
    }

    .footer__container-links, .footer__container-social-medias {
        justify-self: end;

        @include devices(xsmall) {
            justify-self: start !important;
        }

        @include devices(small) {
            justify-self: start !important;
        }
    }

    .app-store-links, .footer__container-social-medias {
        align-self: end;

        @include devices(xsmall) {
            align-self: start;
        }

        @include devices(small) {
            align-self: start;
        }
    }
}


