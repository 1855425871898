@import "../../../styles/mixin";

.section-oque-oferecemos {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: var(--spacing-topbottom-md);
}

.section-oque-oferecemos
    > .container-section-oque-oferecemos
    > .section-oque-oferecemos_figure {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    @include devices(medium) {
        margin-bottom: 60px;
    }

    @include devices(small) {
        margin-bottom: 60px;
    }
    @include devices(xsmall) {
        margin-bottom: 60px;
    }

}

.section-oque-oferecemos
    > .container-section-oque-oferecemos
    > .section-oque-oferecemos_figure
    > img {
    max-width: 600px;
    max-height: 594px;

    height: 100%;

    @include devices(medium) {
        width: 100%;
    }

    @include devices(small){
        width: 100%;
    }

    @include devices(xsmall){
        width: 100%;
    }
}

.section-oque-oferecemos 
    > .container-section-oque-oferecemos {
    width: 100%;
    
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    align-content: center;

    @include devices(xxlarge) {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }

    @include devices(xlarge) {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }

    @include devices(large) {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }
    
}

.section-oque-oferecemos
    > .container-section-oque-oferecemos
    > .container-section-oque-oferecemos__content {
    flex-direction: column;
    display: flex;
    gap: var(--spacing-topbottom-xs) 0;
    max-width: 720px;

    @include devices(medium) {
        gap: 0;
    }

    @include devices(small){
        gap: 0;
    }
    @include devices(xsmall){
        gap: 0;
    }
}

.section-oque-oferecemos
    > .container-section-oque-oferecemos
    > .container-section-oque-oferecemos__content
    > .section-oque-oferecemos_container-title {
    .section-oque-oferecemos_container-title_heading > span {
        color: var(--color-support-purple);
    }

    .section-oque-oferecemos_container-title_heading {
        @include devices(medium){
            font-size: var(--font-size-h2-min);
            line-height: 35px;
            margin-bottom: 1rem;
        }
        @include devices(small){
            font-size: var(--font-size-h3-min);
            line-height: 35px;
        }
        @include devices(xsmall){
            font-size: var(--font-size-h3-min);
            line-height: 35px;
        }
    }
}

.section-oque-oferecemos
    > .container-section-oque-oferecemos
    > .container-section-oque-oferecemos__content
    > .section-oque-oferecemos_container-sub-title {
    .section-oque-oferecemos_container-sub-title_heading {
        margin-top: var(--spacing-inline-nano);
    }
}

.section-oque-oferecemos
    > .container-section-oque-oferecemos
    > .container-section-oque-oferecemos__content
    > .section-oque-oferecemos_container-lista-checking {
    display: flex;
    flex-direction: column;
}

.section-oque-oferecemos
    > .container-section-oque-oferecemos
    > .container-section-oque-oferecemos__content
    > .section-oque-oferecemos_container-lista-checking
    > .section-oque-oferecemos_container-lista-checking__content {
    display: flex;
    gap: 0 var(--spacing-leftright-xs);
    padding: var(--spacing-topbottom-xs) 0;

    @include devices(medium){
        padding: var(--spacing-topbottom-xs) 0;
    }

    @include devices(small){
        padding: var(--spacing-topbottom-nano) 0;
    }

    @include devices(xsmall) {
        padding: var(--spacing-topbottom-nano) 0;
    }

    p {

        @include devices(small){
            font-size: 14px !important;
            line-height: 20px;
        }
        @include devices(xsmall){
            font-size: 14px !important;
            line-height: 20px;
        }
    }
    img {
        height: 34px;
        min-width: 34px;
        
        @include devices(small){
            height: 25px;
            min-width: 25px;
        }
        @include devices(xsmall){
            height: 25px;
            min-width: 25px;
        }

        @include devices(medium){
            height: 25px;
            min-width: 25px;
        }

    }
}

.section-oque-oferecemos > .container-cards_section-oque-oferecemos {
    flex-direction: column;
    display: flex;
    align-items: stretch;
    gap: 24px;
}

.section-oque-oferecemos 
    > .container-cards_section-oque-oferecemos
    > .container-cards_section-oque-oferecemos__card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    padding: var(--spacing-stack-xxs);
    border: 3px solid var(--color-support-bg-gray);
    border-radius: var(--border-radius-lg);

    ul {
        margin: 0;

        small {
            line-height: 15px;
        }


        @include devices(medium){
            margin: var(--spacing-topbottom-sm) 0;
        }

        @include devices(small){
            margin: var(--spacing-topbottom-xs) 0;
        }

        @include devices(xsmall){
            margin: var(--spacing-topbottom-xs) 0;
        }    
    }

    @include devices(medium){
        figure {
            width: 100%;

            img {
                width: 55px;
                height: 55px;
            }
        }
    }

    @include devices(small){
        figure {
            width: 100%;
            
            img {
                width: 55px;
                height: 55px;
            }
        }
    }
    @include devices(xsmall){
        figure {
            width: 100%;

            img {
                width: 55px;
                height: 55px;
            }
        }
    }
}

.section-oque-oferecemos 
    > .container-cards_section-oque-oferecemos
    > .container-cards_section-oque-oferecemos__card {
    figure > small {
        font-size: var(--font-size-h5) !important;

        @include devices(medium) {
            font-size: var(--font-size-h4-min) !important;
        }
        @include devices(small) {
            font-size: var(--font-size-h5-min) !important;
        }
        @include devices(xsmall) {
            font-size: var(--font-size-h5-min) !important;
        }
    }
}

.section-oque-oferecemos 
    > .container-cards_section-oque-oferecemos
    > .container-cards_section-oque-oferecemos__card {

    .container-cards_section-oque-oferecemos__card--ul-support-purple {
        color: var(--color-support-purple-2);
        
        small {
            color: var(--color-support-purple-2);
        }
    }
    
}

.section-oque-oferecemos 
    > .container-cards_section-oque-oferecemos
    > .container-cards_section-oque-oferecemos__card {
    
    .container-cards_section-oque-oferecemos__card--ul-secondary {
            color: var(--color-secondary-purple-300);
    }
    
}

.section-oque-oferecemos 
    > .container-cards_section-oque-oferecemos
    > .container-cards_section-oque-oferecemos__card {
     .container-cards_section-oque-oferecemos__card--ul-primary {
        color: var(--color-primary-yellow-200); 
    }
}