@import '../../../../styles/mixin';

.slide-item {
  display: flex;
  justify-content: center;
  align-items: center;

  @include devices(medium) {
    margin: 0px 40px;
  }

  @include devices(large) {
    margin: 0px 80px;
  }

  @include devices(xlarge) {
    margin: 0px 164px;
  }

  @include devices(xxlarge) {
    margin: 0px 180px;
  }

  &__container {
    margin: var(--spacing-stack-xxxs);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 800px;
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 0px 16px 2px rgba(160, 160, 167, 0.24);
    border-radius: 15px;
    padding: var((--spacing-stack-xl)) var(--spacing-stack-lg);
    font-size: 16px;

    @include devices(xsmall) {
      padding: var(--spacing-stack-xxs);
      min-width: 300px;
      font-size: 12px;
    }

    @include devices(small) {
      padding: var(--spacing-stack-xs);
      min-width: 300px;
      font-size: 12px;
    }

    @include devices(medium) {
      padding: var(--spacing-stack-xs);
      min-width: 600px;
      font-size: 14px;
    }

    max-width: 800px;

    &__figure {
      width: 75px;
      height: 75px;
    }

    &__text {
      font-weight: 700;
      font-size: 2em;
      line-height: 35px;
      margin: var(--spacing-stack-xl) 0px;
    }

    &__client {
      font-weight: 500;
      font-size: 20px;
      line-height: 12px !important;
      color: var(--color-support-purple);
      line-height: 25px;
      margin: 0px !important;
      padding: 0px !important;
    }
  }
}
