@mixin column {
    display: flex;
    flex-direction: column;
}

.formulario_vem_fazer_parte {
    max-width: calc(100svh - 25%);
    width: 100%;
    height: calc(100svh - 30svh);    
        
    gap: var(--spacing-topbottom-sm) 0;
    padding-right: var(--spacing-stack-xxxs);
    
    @include column();

    &__article_title {
        text-align: left;
    }    

    &__body { 
        width: 100%;
        text-align: left;

        @include column();
        
        gap: var(--spacing-topbottom-xs);
    }

    &__footer {
        display: grid;
        grid-template-columns: 1fr 2fr;
    
        justify-content: flex-end;
        
        div {
            grid-column-start: 2;
        }
    }

}