
@import "../../../../../styles/mixin";

.figure__image-capa > img {
  z-index: 0;

  width: 100%;
  // height: 90px;
  object-fit: contain;

  @include devices(xxlarge) {
    // min-width: 580px;
    // max-width: 700px;
    // max-height: 850px;
    // min-height: 700px;
    // width: 100%;
    // height: 100%;

    // bottom: 14px;
    // margin-left: 100px;
  }

  @include devices(xlarge) {
    max-width: 580px;
    max-height: 620px;
    // width: 100%;
    // height: 100%;

    // bottom: 1.7px;
    // right: 90px;
  }

  @include devices(large) {
    max-width: 500px;
    max-height: 550px;
    // width: 100%;
    // height: 100%;

    // bottom: 1.7px;
    // right: 140px;
  }

  @include devices(medium) {
    max-width: 500px;
    max-height: 600px;
    // width: 100%;
    // height: 100%;

    // bottom: 20px;
    // right: -30px;
  }

  @include devices(small) {
    max-width: 330px;
    max-height: 460px;
    // width: 100%;
    // height: 100%;

    // bottom: 40px;
    // right: 100px;
  }

  @include devices(xsmall) {
    //max-width: 300px;
    //max-height: 280px;
    // width: 230px;
    // height: 350px;

    // bottom: 9.4px;
    // margin-left: -50px;
  }
}
