@import "../../../../styles/mixin";

.figure__image-capa > img {
  z-index: 0;

  width: 134px;
  height: 233px;
  object-fit: contain;

  @include devices(xxlarge) {
  }

  @include devices(xlarge) {
  }

  @include devices(large) {
  }

  @include devices(medium) {
  }

  @include devices(small) {
  }

  @include devices(xsmall) {
  }
}
