@import '../../../styles/mixin';

.section-simulacao {
  margin-top: var(--spacing-stack-xxxl);
  margin-bottom: var(--spacing-stack-xxl);

  & > h2 {
    margin: 32px 0px 48px 16px;
    width: 80%;
    font-weight: 700;
    font-size: 4rem;
    line-height: 70px;

    @include devices(xsmall) {
      padding: 0px 0px 32px 16px;
      margin: 0px 0px 0px 0px;
      width: 100%;
      font-size: 2rem;
      line-height: 40px;
    }

    @include devices(small) {
      width: 100%;
      font-size: 2.8rem;
    }

    @include devices(medium) {
      width: 100%;
      font-size: 3.5rem;
    }
  }
}
