@import "../../../../../styles/mixin";

.modal-submitted {
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
  padding: 32px 200px;

  @include devices(xsmall) {
    padding: 0 32px;
    height: 80vh;
  }

  @include devices(small) {
    padding: 0 32px;
    height: 80vh;
  }

  @include devices(medium) {
    padding: 0 32px;
    height: 80vh;
  }

  h3 {
    color: var(--color-write);

    @include devices(xsmall) {
      display: none;
    }

    @include devices(small) {
      display: none;
    }

    @include devices(medium) {
      display: none;
    }

    @include devices(large) {
      display: none;
    }

    large

    &:nth-child(1) {
      opacity: .1;
    }

    &:nth-child(2) {
      opacity: .5;
    }
  }

  p {
    margin-top: 52px;
    text-align: center;
    font-weight: 700;
    font-size: 23px !important;
  }

  img {
    margin-top: 98px;

    @include devices(xsmall) {
        margin-top: 20%;
    }

    @include devices(small) {
      margin-top: 20%;
    }

    @include devices(medium) {
      margin-top: 20%;
    }
  }
}