@import "../../../../styles/mixin";

.figure__image-capa > img {
  z-index: 0;
  object-fit: contain;
  width: 253px;

  @include devices(xxlarge) {
    
  }

  @include devices(xlarge) {
  }

  @include devices(large) {
    width: 200px;
  }

  @include devices(medium) {
    width: 133px;
  }

  @include devices(small) {
    width: 133px;
  }

  @include devices(xsmall) {
    width: 90px;
  }
}
